import React from "react"

import Layout from "../components/layout"
import Header from "../components/header"
import Content from "../components/content"
import Footer from "../components/footer"
import { Link } from "gatsby"
import SEO from "../components/seo"
import logo from "../images/logo.png"
import { lang, locale } from "moment"

const Page = ({data}) => {
    const page = data.graphAPI.page
    const languages = data.site.siteMetadata.languages

    const LocationNav = () => {
        if(page.localizations.length !== 0) {
            // has localization
            return (
                <div className="lang-select">
                    {/* <p>Select Language</p> */}
                    <div className="locale-list">
                    {languages.langs.map(language => {
                        // Is this language published
                        let langExists = false;
                        if(page.locale === language.key) {
                            langExists = true;
                        } else {
                            page.localizations.forEach( locale => {
                                if(locale.locale === language.key) {
                                    langExists = true;
                                }
                            })
                        }

                        let langLink = '/'; // failure goes home

                        if(page.locale === languages.defaultLangKey) {
                            // page is default
                            if(language.key === languages.defaultLangKey) {
                                langLink = `/${page.slug}`
                            } else {
                                page.localizations.forEach(locale => {
                                    if(language.key === locale.locale) {
                                        langLink = `/${language.key}/${page.slug}`
                                    }
                                })
                            }
                        } else {
                            // page is not default
                            let primarySlug = '/'; // failure goes home
                            page.localizations.forEach(locale => {
                                if(locale.locale === languages.defaultLangKey) {
                                    primarySlug = `${locale.slug}`
                                }
                            })

                            if(language.key === page.locale) {
                                langLink = `/${page.locale}/${primarySlug}`
                            } else {
                                page.localizations.forEach(locale => {
                                    if(language.key === locale.locale) {
                                        if(locale.locale === languages.defaultLangKey) {
                                            langLink = `/${primarySlug}`
                                        } else {
                                            langLink = `/${locale.locale}/${primarySlug}`
                                        }
                                    }
                                })
                            }
                            

                        }
                        if(langExists) {
                            return (<Link to={langLink} className={`button is-small ${(language.key === page.locale) ? 'is-primary' : ''} locale-item`}>{language.label}</Link>);
                        } else {
                            return (<></>)
                        }
                    })}
                    </div>
                    <hr />
                </div>);
        } else {
            return (<></>)
        }
    }

    return (
        <Layout>
            <SEO title={page.title} />
                    <LocationNav />
                    {page.content[0].__typename !== 'GRAPHAPI_ComponentPageSplitContentImage' ? (
                        <div className="title-section">
                            <h1 className="title is-size-1">{page.title}</h1>
                        </div>
                    ) : (<></>)}
                    <Content content={page.content} />


        </Layout>
    )
}

export default Page

export const pageQuery = graphql`
    query pageById($id: ID!) {
        site {
            siteMetadata {
                languages {
                    langs {
                        key
                        label
                    }
                    defaultLangKey
                }
            }
        }
        graphAPI {
            page(id: $id) {
                id
                slug
                title
                locale
                localizations {
                    id
                    locale
                    slug
                }
                content {
                    ... on GRAPHAPI_ComponentPageBaseContent {
                        ...baseContent
                    }
                    ... on GRAPHAPI_ComponentPageImage {
                        ...pageImage
                    }
                    ... on GRAPHAPI_ComponentPageLinkCardWithImage {
                        ...linkCardWithImage
                    }
                    ... on GRAPHAPI_ComponentPageSplitContentImage {
                        ...splitContentImage
                    }
                    ... on GRAPHAPI_ComponentPageGallery {
                        ...pageGallery
                    }
                    ... on GRAPHAPI_ComponentPageLinkList {
                        ...pageLinkList
                    }

                }
            }
        } 
    }
`


